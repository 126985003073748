import "./App.css";
import EventTimerPage from "pages/eventTimer";
import SettingPage, { defaultSetting } from "pages/setting";
import DebugPage from "pages/debug";
import { useState } from 'react';

function App() {
  const [showSetting, setShowSetting] = useState(false);
  const [setting, setSetting] = useState({ ...defaultSetting });

  function onClickSettingButton() {
    setShowSetting(true)
  }

  function onCloseSettingButton() {
    setShowSetting(false)
  }

  return (
    <div className="App">
      {showSetting ? <SettingPage setting={setting} setSetting={setSetting} onCloseSettingButton={onCloseSettingButton} /> : null}
      <EventTimerPage className={showSetting ? "hidden" : ""} setting={setting} onClickSettingButton={onClickSettingButton} />
    </div>
  );
}

export default App;
