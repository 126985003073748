import { Events } from "logics/eventConfig";
import { useState } from "react";

export const defaultSetting = Events.reduce((acc, event) => {
  return {
    ...acc,
    [event.type]: {
      enable: true,
      enableSound: true,
    }
  }
}, {})

function SettingItem(props) {
  const {
    event,
    enableEvent,
    enableSound,
    onEnableEventChange,
    onEnableSoundChange,
  } = props;

  const { name, images, sounds } = event;
  const enableSoundChange = sounds.length > 0;

  return (
    <div className="settingItem">
      <div className="settingItemDesc">
        <img
          className="settingItemImage"
          src={images[0]}
          alt={`${event.type}`}
        />
        <p className="settingItemName">{name}</p>
      </div>
      <div className="settingItemInput">
        <div className="settingCheckItem">
          <label>开启</label>
          <input
            className="settingItemCheckbox"
            type="checkbox"
            checked={enableEvent}
            onChange={(e) => onEnableEventChange(event.type, e.target.checked)}
          />
        </div>
        <div className="settingCheckItem">
          <label>语音播报</label>
          <input
            className="settingItemCheckbox"
            type="checkbox"
            disabled={!enableSoundChange}
            checked={enableSoundChange ? enableSound : undefined}
            onChange={(e) => onEnableSoundChange(event.type, e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
}

export default function SettingPage({ setting, setSetting, onCloseSettingButton }) {
  const [pageSetting, setPageSetting] = useState({ ...setting });
  console.log(pageSetting)

  function handleClickEnableEventCheckbox(eventType, enable) {
    const newSetting = { ...pageSetting };
    const eventSetting = pageSetting[eventType] ? pageSetting[eventType] : {};
    const newEventSetting = {
      ...eventSetting,
      enable: enable,
    }
    newSetting[eventType] = {};
    setPageSetting({
      ...pageSetting,
      [eventType]: newEventSetting,
    });
  }

  function handleClickEnableSoundCheckbox(eventType, enable) {
    const newSetting = { ...pageSetting };
    const eventSetting = pageSetting[eventType] ? pageSetting[eventType] : {};
    const newEventSetting = {
      ...eventSetting,
      enableSound: enable,
    }
    newSetting[eventType] = {};
    setPageSetting({
      ...pageSetting,
      [eventType]: newEventSetting,
    });
  }

  function handleClickCancelButton() {
    onCloseSettingButton();
  }

  function handleClickSaveButton() {
    setSetting(pageSetting);
    onCloseSettingButton();
  }

  const eventSettingItems = Events.map((event) => {
    return <SettingItem
      key={event.type}
      event={event}
      enableEvent={pageSetting[event.type]?.enable ? true : false}
      enableSound={pageSetting[event.type]?.enableSound ? true : false}
      onEnableEventChange={handleClickEnableEventCheckbox}
      onEnableSoundChange={handleClickEnableSoundCheckbox}
    />;
  });

  return (
    <div className="settingPage">
      <section className="settingHeader">
        <h1>设置</h1>
      </section>
      <section className="settingSection">
        <h2>事件设置</h2>
        <div className="eventSettingItems">{eventSettingItems}</div>
      </section>
      <section className="settingButtons">
        <button className="controlButton" onClick={handleClickCancelButton}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="buttonIcon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
        <button className="controlButton" onClick={handleClickSaveButton}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="buttonIcon">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </svg>
        </button>
      </section>
    </div>
  );
}
