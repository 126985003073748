import { GetEventByType } from "./eventConfig.js";

export const EventTypes = {
  CreepStacking: "CreepStacking",
  DayTime: "DayTime",
  NightTime: "NightTime",
  WisdomRune: "WisdomRune",
  BountyRune: "BountyRune",
  WaterRune: "WaterRune",
  PowerRune: "PowerRune",
  HealingLotus: "HealingLotus",
  NeutralItemTier1: "NeutralItemTier1",
  NeutralItemTier2: "NeutralItemTier2",
  NeutralItemTier3: "NeutralItemTier3",
  NeutralItemTier4: "NeutralItemTier4",
  NeutralItemTier5: "NeutralItemTier5",
  Tormentor: "Tormentor",
};

let cachedEvents = null;

function GenerateEvents() {
  if (cachedEvents) {
    return cachedEvents;
  }

  let events = [
    // 中立物品。Neutral Items
    {
      type: EventTypes.NeutralItemTier1,
      seconds: 7 * 60, // 7 分钟
    },
    {
      type: EventTypes.NeutralItemTier2,
      seconds: 17 * 60, // 17 分钟
    },
    {
      type: EventTypes.NeutralItemTier3,
      seconds: 27 * 60, // 27 分钟
    },
    {
      type: EventTypes.NeutralItemTier4,
      seconds: 36 * 60 + 40, // 36:40
    },
    {
      type: EventTypes.NeutralItemTier5,
      seconds: 60 * 60, // 60 分钟
    },
    // 痛苦魔方
    {
      type: EventTypes.Tormentor,
      seconds: 20 * 60, // 20 分钟
    },
  ];
  // 生成 120 分钟内的事件
  for (let seconds = 0; seconds < 60 * 120 + 1; seconds++) {
    // 堆叠野怪
    if (seconds > 60 && seconds % 60 === 0) {
      events.push({
        type: EventTypes.CreepStacking,
        seconds: seconds,
      });
    }

    // day, night cycle
    // day time
    if (seconds >= 0 && seconds % (10 * 60) === 0) {
      events.push({
        type: EventTypes.DayTime,
        seconds: seconds,
      });
    }
    // night time
    if (seconds >= 5 * 60 && (seconds - 5 * 60) % (10 * 60) === 0) {
      events.push({
        type: EventTypes.NightTime,
        seconds: seconds,
      });
    }

    // wisdom rune
    if (seconds > 0 && seconds % (7 * 60) === 0) {
      // 每 7 分钟经验府
      events.push({
        type: EventTypes.WisdomRune,
        seconds: seconds,
      });
    }

    // bounty rune
    if (seconds >= 0 && seconds % (3 * 60) === 0) {
      // 每 3 分钟赏金神符
      events.push({
        type: EventTypes.BountyRune,
        seconds: seconds,
      });
    }

    // water rune
    if (seconds > 0 && seconds < 6 * 60 && seconds % (2 * 60) === 0) {
      events.push({
        type: EventTypes.WaterRune,
        seconds: seconds,
      });
    }

    // power rune
    if (seconds > 4 * 60 && seconds % (2 * 60) === 0) {
      events.push({
        type: EventTypes.PowerRune,
        seconds: seconds,
      });
    }

    // 莲花（Healing Lotus）
    if (seconds > 0 && seconds % (3 * 60) === 0) {
      events.push({
        type: EventTypes.HealingLotus,
        seconds: seconds,
      });
    }
  }

  // 根据时间排序
  events.sort((a, b) => a.seconds - b.seconds);

  // 填充数据
  return events.map((event) => {
    const eventData = GetEventByType(event.type);
    return {
      seconds: event.seconds,
      ...eventData,
    };
  });
}

export function GetEventsBySeconds(seconds, setting) {
  let events = GenerateEvents();

  // 只保留未来 5 分钟的事件
  let filteredEvents = events.filter(
    (event) => {
      const allowShowByTime = event.seconds >= seconds && event.seconds <= seconds + 5 * 60;
      const allowShowBySetting = setting[event.type]?.enable === true;

      return allowShowByTime && allowShowBySetting
    }
  );

  return filteredEvents;
}

export function GetPlayableSounds(events, seconds, setting) {
  let sounds = events.reduce((acc, event) => {
    if (!setting[event.type]?.enableSound) {
      return acc;
    }

    let eventSounds = event.sounds.filter((sound) => {
      let soundSeconds = event.seconds + sound.timing;
      console.log(
        `event.seconds: ${event.seconds} sound.timing: ${sound.timing} soundSeconds: ${soundSeconds} seconds: ${seconds}`
      );
      return soundSeconds === seconds;
    });
    return [...acc, ...eventSounds];
  }, []);

  sounds.sort((a, b) => b - a);
  console.log("seconds ", seconds, " sounds ", sounds);

  return sounds.length > 0 ? sounds.slice(0, 1) : sounds;
}
