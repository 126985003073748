export const Events = [
  {
    type: "CreepStacking",
    name: "堆野",
    images: ["images/Satyr_Tormenter_model.webp"],
    sounds: [
      {
        timing: -30,
        url: "sounds/hoodwind_voices/creep_stacking.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "DayTime",
    name: "白天",
    images: ["images/Supernova_icon.webp"],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/into_day.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NightTime",
    name: "夜晚",
    images: ["images/Eclipse_icon.webp"],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/into_night.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "WisdomRune",
    name: "智慧神符",
    images: ["images/Rune_of_Wisdom_model.webp"],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/wisdom_rune_15s.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "BountyRune",
    name: "赏金神符",
    images: ["images/Rune_of_Bounty_model.webp"],
    sounds: [
      //   {
      //     timing: -15,
      //     url: "sounds/hoodwind_voices/wisdom_rune_15s.wav",
      //     priority: 100,
      //   },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "WaterRune",
    name: "圣水神符",
    images: ["images/Rune_of_Water_model.webp"],
    sounds: [
      //   {
      //     timing: -15,
      //     url: "sounds/hoodwind_voices/wisdom_rune_15s.wav",
      //     priority: 100,
      //   },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "PowerRune",
    name: "河道神符",
    images: [
      "images/Rune_of_Amplify_Damage_model.webp",
      "images/Rune_of_Arcane_model.webp",
      "images/Rune_of_Haste_model.webp",
      "images/Rune_of_Illusion_model.webp",
      "images/Rune_of_Invisibility_model.webp",
      "images/Rune_of_Shield_model.webp",
    ],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/power_rune_spawn.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "HealingLotus",
    name: "治疗莲花",
    images: ["images/Pluck_Lotus_icon.webp"],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/healing_lotus_15s.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NeutralItemTier1",
    name: "一级中立物品",
    images: ["images/Tier_1_Token_icon.webp"],
    sounds: [
      {
        timing: 0,
        url: "sounds/hoodwind_voices/tier_1_natural_item.wav",
        priority: 50,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NeutralItemTier2",
    name: "二级中立物品",
    images: ["images/Tier_2_Token_icon.webp"],
    sounds: [
      {
        timing: 0,
        url: "sounds/hoodwind_voices/tier_2_natural_item.wav",
        priority: 50,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NeutralItemTier3",
    name: "三级中立物品",
    images: ["images/Tier_3_Token_icon.webp"],
    sounds: [
      {
        timing: 0,
        url: "sounds/hoodwind_voices/tier_3_natural_item.wav",
        priority: 50,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NeutralItemTier4",
    name: "四级中立物品",
    images: ["images/Tier_4_Token_icon.webp"],
    sounds: [
      {
        timing: 0,
        url: "sounds/hoodwind_voices/tier_4_natural_item.wav",
        priority: 50,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
  {
    type: "NeutralItemTier5",
    name: "五级中立物品",
    images: ["images/Tier_5_Token_icon.webp"],
    sounds: [
      {
        timing: 0,
        url: "sounds/hoodwind_voices/tier_5_natural_item.wav",
        priority: 50,
      },
    ],
    play_sound_setting: {},
  },
  {
    type: "Tormentor",
    name: "痛苦魔方",
    images: [
      "images/Tormentor_Radiant_model.webp",
      "images/Tormentor_Dire_model.webp",
    ],
    sounds: [
      {
        timing: -15,
        url: "sounds/hoodwind_voices/tormentor_spawn.wav",
        priority: 100,
      },
    ],
    play_sound_setting: {
      allow_play_in_time_range: [0, 600],
    },
  },
];

const EventMap = Events.reduce((acc, event) => {
  acc[event.type] = event;
  return acc;
}, {});

export function GetEventByType(type) {
  return EventMap[type];
}
