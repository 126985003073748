import { useEffect, useState } from "react";
import { GetEventsBySeconds, GetPlayableSounds } from "logics/eventLogic.js";
import NoSleep from "nosleep.js";

function Header({ onClickSettingButton }) {
  return (
    <div className="eventTimerPageSection eventTimerHeader">
      <ToolsHeader onClickSettingButton={onClickSettingButton} />
      <div className="introduction">
        <h1 className="introductionTitle">Dota2 事件计时器</h1>
        <p className="introductionDescription">
          一个 Dota2
          的事件计时器，通过语音播报和页面展示，告知即将发生的事件，帮助玩家更好的进行游戏。
        </p>
      </div>
    </div>
  );
}

function EventItem({ event, gameSeconds }) {
  // console.log(event);
  const { seconds, name, images } = event;
  let secondsDelta = seconds - gameSeconds;
  let displayRestSeconds =
    secondsDelta < 30 ? (
      <span className="highlight">{FormatSeconds(secondsDelta)}</span>
    ) : (
      FormatSeconds(secondsDelta)
    );

  let [imageIndex, setImageIndex] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="eventItem">
      <img
        className="eventItemImage"
        src={images[imageIndex]}
        alt="rune of bounty"
      />
      <p>{name}</p>
      <p>还有 {displayRestSeconds}</p>
    </div>
  );
}

function EventTable({ gameEvents, gameSeconds }) {
  const eventItems = gameEvents.map((event) => (
    <EventItem
      key={event.type + event.seconds}
      event={event}
      gameSeconds={gameSeconds}
    />
  ));

  return (
    <div className="eventTimerPageSection eventTable">
      {eventItems}
      <p className="eventTableEnd">当前只展示 5 分钟内的事件</p>
    </div>
  );
}

function PlayButton({ isPause, onClick }) {
  let content;
  if (isPause) {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="buttonIcon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
        />
      </svg>
    );
  } else {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="buttonIcon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 5.25v13.5m-7.5-13.5v13.5"
        />
      </svg>
    );
  }

  return (
    <button className="controlButton" onClick={onClick}>
      {content}
    </button>
  );
}

function ControlPanel(props) {
  const {
    gameSeconds,
    isPause,
    enablePlayButtonHint,
    onPlayButtonClick,
    onMinusOneSecondsButtonClick,
    onMinusTenSecondsButtonClick,
    onAddOneSecondsButtonClick,
    onAddTenSecondsButtonClick,
  } = props;

  return (
    <div className="controlPanel eventTimerPageSection">
      <div className="clockPanel">
        <p className="clock">当前时间 {FormatSeconds(gameSeconds)} </p>
        <p className="playButtonHint">
          {enablePlayButtonHint
            ? "（点击播放按钮开始）"
            : "（点击微调按钮以对齐游戏时间）"}
        </p>
      </div>
      <div className="controlButtons">
        <button
          onClick={onMinusTenSecondsButtonClick}
          className="controlButton"
        >
          -10s
        </button>
        <button
          onClick={onMinusOneSecondsButtonClick}
          className="controlButton"
        >
          -1s
        </button>
        <PlayButton isPause={isPause} onClick={onPlayButtonClick} />
        <button onClick={onAddOneSecondsButtonClick} className="controlButton">
          +1s
        </button>
        <button onClick={onAddTenSecondsButtonClick} className="controlButton">
          +10s
        </button>
      </div>
    </div>
  );
}

function FormatSeconds(seconds) {
  let displayMinutes = Math.floor(Math.abs(seconds) / 60);
  let displaySeconds = Math.floor(Math.abs(seconds) % 60);

  displayMinutes =
    displayMinutes >= 10 ? `${displayMinutes}` : `0${displayMinutes}`;

  displaySeconds =
    displaySeconds >= 10 ? `${displaySeconds}` : `0${displaySeconds}`;

  return `${seconds < 0 ? "-" : ""}${displayMinutes}:${displaySeconds}`;
}

function ToolsHeader({ onClickSettingButton }) {
  return <div className="eventTimerPageToolsHeader">
    <button className="toolButton" onClick={onClickSettingButton}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="toolButtonIcon">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
    </button>
  </div>
}

export default function EventTimerPage({ setting, onClickSettingButton }) {
  const [isClickPlay, setIsClickPlay] = useState(false);
  const [enablePlayButtonHint, setEnablePlayButtonHint] = useState(true);
  const [isPause, setIsPause] = useState(true);
  const [gameSeconds, setGameSeconds] = useState(-90);
  const [gameEvents, setGameEvents] = useState([]);
  const [audio, setAudio] = useState(new Audio());
  const [noSleep, setNoSleep] = useState(new NoSleep());

  // audio.src = "sounds/audio-blank.mp3";

  // timer
  useEffect(() => {
    let interval = setInterval(() => {
      if (isPause) {
        return;
      }
      setGameSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isPause]);

  // update events
  useEffect(() => {
    setGameEvents(GetEventsBySeconds(gameSeconds, setting));
  }, [gameSeconds]);

  // play sounds
  useEffect(() => {
    if (isPause) {
      return;
    }

    let playableSounds = GetPlayableSounds(
      GetEventsBySeconds(gameSeconds, setting),
      gameSeconds,
      setting,
    );
    playableSounds.forEach((sound) => {
      audio.src = sound.url;
      audio.load();
      audio.play().then(() => {
        console.log("play " + sound.url);
      });
    });
  }, [isPause, gameSeconds]);

  // control buttons
  function handleClickPlayButton() {
    setIsPause(!isPause);
    setEnablePlayButtonHint(false);
    setIsClickPlay(true);
    if (!isClickPlay) {
      // init audio
      audio.src = "sounds/hoodwind_voices/intro.wav";
      // 介绍语言
      audio.load();
      audio.play().then(() => {
        // audio.pause();
      });
      console.log("init audio");

      // init no sleep
      try {
        noSleep.enable();
      } catch (err) {
        alert(err);
      }
    }
  }

  function handleAddTenSecondsButtonClick() {
    setGameSeconds((prevSeconds) => prevSeconds + 10);
  }

  function handleAddOneSecondsButtonClick() {
    setGameSeconds((prevSeconds) => prevSeconds + 1);
  }

  function handleMinusTenSecondsButtonClick() {
    setGameSeconds((prevSeconds) => prevSeconds - 10);
  }

  function handleMinusOneSecondsButtonClick() {
    setGameSeconds((prevSeconds) => prevSeconds - 1);
  }

  return (
    <div className="eventTimerPage">
      <Header onClickSettingButton={onClickSettingButton} />
      <EventTable gameEvents={gameEvents} gameSeconds={gameSeconds} />
      <ControlPanel
        gameSeconds={gameSeconds}
        isPause={isPause}
        enablePlayButtonHint={enablePlayButtonHint}
        onPlayButtonClick={handleClickPlayButton}
        onAddOneSecondsButtonClick={handleAddOneSecondsButtonClick}
        onAddTenSecondsButtonClick={handleAddTenSecondsButtonClick}
        onMinusOneSecondsButtonClick={handleMinusOneSecondsButtonClick}
        onMinusTenSecondsButtonClick={handleMinusTenSecondsButtonClick}
      />
    </div>
  );
}
